<template>
    <div class="work-roles">
        <div class="work-roles-selection">
            <div class="selection-item" :class="allTypesSelected ? 'active': ''" v-on:click="selectAll()">Alle</div>
            <div class="selection-item" :class="currentSelectedType == 'synchron' ? 'active': ''" v-on:click="selectType('synchron')"><span class="mdi mdi-movie-open"></span> Synchron</div>
            <div class="selection-item" :class="currentSelectedType == 'hoerbuch' ? 'active': ''" v-on:click="selectType('hoerbuch')"><span class="mdi mdi-podcast"></span> Hörbuch</div>
            <div class="selection-item" :class="currentSelectedType == 'werbung' ? 'active': ''" v-on:click="selectType('werbung')"><span class="mdi mdi-cart"></span> Werbung</div>
            <div class="selection-item" :class="currentSelectedType == 'videospiel' ? 'active': ''" v-on:click="selectType('videospiel')"><span class="mdi mdi-google-controller"></span> Videospiel</div>
            <div class="selection-item" :class="currentSelectedType == 'dialogbuch' ? 'active': ''" v-on:click="selectType('dialogbuch')"><span class="mdi mdi-book-open-page-variant"></span> Dialogbuch</div>
        </div>

        <div class="work-roles-list">
            <WorkRoleItem v-for="workRole in allWorkRoles" v-bind="workRole" v-bind:key="workRole.id" v-bind:isActive="currentSelectedType == workRole.role_type || allTypesSelected" />
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';
    
    import WorkRoleItem from '@/components/WorkRoles/WorkRoleItem.vue';

    export default {
        name: 'NewsItem',
        data: function() {
            return {
                vfapi: null,
                allWorkRoles: [],
                allTypesSelected: true,
                currentSelectedType: ""
            }
        },
        components: {
            WorkRoleItem,
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('work_role', '*,cover_image.data.url').then(data => {
                this.$data.allWorkRoles = data;
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            });
        },
        methods: {
            selectAll: function() {
                this.$data.allTypesSelected = true;
                this.$data.currentSelectedType = "";
            },
            selectType: function(newType) {
                this.$data.allTypesSelected = false;
                this.$data.currentSelectedType = newType;
            }
        }
    };
</script>

<style scoped lang="less">
    .work-roles {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: 20px;
        padding: 50px 0px;
        padding-top: 25px;
        max-width: 1200px;
        margin: 0 auto;

        & .work-roles-selection {
            display: flex;

            & .selection-item {
                padding: 10px 25px;
                border-radius: 100px;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.2em;
                margin-right: 15px;
                transform: skew(0deg, -2deg);
                transition: 0.15s ease-in-out all;

                &.active {
                    background: rgba(255,255,255,0.17);
                }
                &:hover {
                    background: rgba(255,255,255,0.07);
                    cursor: pointer;
                }
            }
        }
        & .work-roles-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }
    }

    @media screen and (max-width: 1250px) {
        .work-roles {
            width: 95vw;

            & .work-roles-selection {
                flex-wrap: wrap;
                align-items: center;
            }

            & .work-roles-list {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    @media screen and (max-width: 700px) {
        .work-roles {
            & .work-roles-list {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>
