<template>
    <header :class="fixedHeader ? 'fixedHeader' : ''">
        <transition name="fade">
            <router-link :to="{ name: 'Index' }" v-if="showLogo"><div class="brand">Vincent<br />Fallow</div></router-link>
        </transition>
        <nav>
            <a v-for="socialLink in socialLinks" v-bind:key="socialLink.id" :href="socialLink.url" target="_blank"><i :class="'mdi mdi-' + socialLink.icon_name"></i></a>
            <ContactButton />
        </nav>
    </header>
</template>

<script>
    import VFAPI from '@/modules/module.api.js';
    
    import ContactButton from '@/components/ContactForm/ContactButton.vue';

    export default {
        name: 'Header',
        components: {
            ContactButton,
        },
        data: function() {
            return {
                vfapi: null,
                showLogo: true,
                fixedHeader: false,
                socialLinks: []
            }
        },
        created: function() {
            document.addEventListener('scroll', this.checkScroll);
        },
        destroyed: function() {
            document.removeEventListener('scroll', this.checkScroll);
        },
        mounted: function() {
            this.checkHeader();
            
            this.$data.vfapi = new VFAPI();

            this.$data.vfapi.getItems('social_links').then(data => {
                this.$data.socialLinks = data;
            });
        },
        watch: {
            '$route' () {
                this.checkHeader();
            },
        },
        methods: {
            checkHeader: function() {
                if (this.$route.name === 'Index') {
                    this.$data.fixedHeader = true;
                }
                else {
                    this.$data.fixedHeader = false;
                }
            },
            checkScroll: function() {
                if(window.innerWidth < 700) {
                    this.$data.showLogo = (window.scrollY < 100);
                }
            } 
        }
    };
</script>

<style scoped lang="less">
    header {
        padding: 35px 50px;
        z-index: 10;
        display: grid;
        background: linear-gradient(180deg, rgba(0,0,0,0.8), transparent);
        grid-template-columns: 1fr auto;

        & a {
            color: #fff;
            text-decoration: none;
        }
        & .brand {
            font-size: 34px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 0.73em;
            letter-spacing: -0.06em;
        }
        & nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            & a {
                display: block;
                color: #fff;
                font-size: 40px;
                margin-right: 20px;
                transition: 0.2s ease-in-out all;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }
            }
        }

        &.fixedHeader {    
            position: fixed;
            left: 0px;
            top: 0px;
            right: 0px;
        }
    }

    @media screen and (max-width: 700px) {
        & header {
            padding: 0px;
            display: grid;
            grid-template-columns: 1fr;
            background: transparent;

            & .brand {
                grid-row: 2;
                display: flex;
                padding: 10px 15px;
                justify-content: center;
                background: linear-gradient(180deg, rgba(0,0,0,0.8), transparent);
            }
            & nav {
                grid-row: 1;
                padding: 10px 15px;
                background: #000;
                width: 100%;
                justify-content: center;

                & a {
                    font-size: 24px;
                }
            }
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
