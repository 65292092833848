<template>
    <div class="news-item" v-on:click="clickItem()">
        <div class="newsitem-cover" :style="'background-image: url(' + cover_image.data.full_url + ')'">
            <img src="@/assets/img/slant.svg" alt="Slanted Graphic" />
        </div>
        <div class="newsitem-title">
            {{ title }}
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'NewsItem',
        props: [
            'cover_image',
            'title',
            'url'
        ],
        methods: {
            clickItem: function() {
                if(this.$props.url != null) {
                    window.open(this.$props.url, '_blank');
                }
            }
        }
    };
</script>

<style scoped lang="less">
    .news-item {
        background: #1D1D1D;
        border-radius: 6px;
        transform: skew(0deg, -2deg);
        transition: 0.2s ease-in-out all;

        & .newsitem-cover {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            height: 220px;
            background-position: center;
            background-size: cover;
            position: relative;

            & img {
                position: absolute;
                bottom: -1px;
                left: 0px;
                right: 0px;
                height: 50px;
                width: 100%;
            }
        }
        & .newsitem-title {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            padding: 25px;
            transform: translateY(-10px);
        }

        &:hover {
            transform: translateY(-20px) skew(0deg, -2deg);
            cursor: pointer;
        }
    }
</style>
