import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.use(require('vue-cookies'));
Vue.$cookies.config('30d');

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
