<template>
    <div class="page-vita">
        <div class="login" v-if="!isLoggedIn">
            <div class="login-box">
                <span class="login-error" v-if="loginError">Logindaten inkorrekt.</span>
                <input type="text" v-model="loginUser" placeholder="Username" />
                <input type="password" v-model="loginPass" placeholder="Password" />
                <button v-on:click="login()">Login</button>
            </div>
        </div>
        <div class="vita-dashboard" v-if="isLoggedIn">
            <header>
                <span>Vita</span>
                <div class="button-actions">
                    <button v-on:click="print()">Drucken</button>
                    <button v-on:click="logout()">Logout</button>
                </div>
            </header>
            <div class="search">
                <input type="search" v-model="searchQuery" placeholder="Suchanfrage..." />
            </div>
            <div class="general-info">
                <img src="@/assets/img/avatar.png" />
                <div>
                    <strong>Name:</strong> Vincent Fallow<br />
                    <strong>Geboren:</strong> 06.09.1989<br />
                    <strong>Wohnort:</strong> Hamburg<br /><br />
                    <strong>Stimmalter:</strong> 17-40<br />
                    <strong>Stimmfarbe:</strong> warm, zimtig, rauchig, wandelbar<br />
                    <strong>Sprachen:</strong> Deutsch, US Englisch (fließend)
                </div>
                <div>
                    <strong>Bildung:</strong>
                    <ul>
                        <li>Minges Synchronworkshop<br /><em>2019 - Peter Minges</em></li>
                        <li>Dialogbuch Workshop ISFF<br /><em>2020 - Christian Gundlach</em></li>
                    </ul>
                    <strong>Wohnmöglichkeiten:</strong> Deutschlandweit
                </div>
            </div>
            <div class="items">
                <div class="header item">
                    <div><input type="checkbox" :checked="allChecked" v-on:click="switchAll()" /></div>
                    <div>Live?</div>
                    <div>Rolle</div>
                    <div>Produktion</div>
                    <div>Studio</div>
                    <div>Regie</div>
                </div>
                <label v-for="workItem in visibleWorkItems" v-bind:key="workItem.id" class="item" :class="workItem.checked ? 'checked' : ''">
                    <div><input type="checkbox" v-model="workItem.checked" /></div>
                    <div>{{ workItem.status == 'published' ? 'Ja' : 'Nein' }}</div>
                    <div>{{ workItem.role_name }}</div>
                    <div>{{ workItem.role_work }}</div>
                    <div>{{ workItem.role_studio }}</div>
                    <div>{{ workItem.role_direction }}</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import VFAPI from '@/modules/module.api.js';

    export default {
        name: 'Vita',
        data: function() {
            return {
                vfapi: null,
                isLoggedIn: false,
                searchTimeout: null,
                loginError: false,
                loginUser: "",
                loginPass: "",
                loginToken: "",
                workItems: [],
                searchQuery: "",
                visibleWorkItems: [],
                allChecked: false
            }
        },
        components: {
        },
        mounted: function() {
            this.$data.vfapi = new VFAPI();

            this.checkLogin();
        },
        methods: {
            checkLogin: function() {
                if(this.$cookies.get('token') != null) {
                    this.$data.loginToken = this.$cookies.get('token');
                    this.$data.isLoggedIn = true;
                    this.$data.loginError = false;
                    this.$data.loginUser = "";
                    this.$data.loginPass = "";

                    this.loadWorkRoles();
                }
            },
            login: function() {
                this.$data.vfapi.authenticate(this.$data.loginUser, this.$data.loginPass).then(data => {
                    if(data.token) {
                        this.$data.loginError = false;
                        this.$data.isLoggedIn = true;
                        this.$data.loginUser = "";
                        this.$data.loginPass = "";
                        this.$data.loginToken = data.token;

                        this.$cookies.set('token', data.token);

                        this.loadWorkRoles();
                    }
                }).catch(error => {
                    this.$data.loginError = true;
                });
            },
            print: function() {
                window.print();
            },
            logout: function() {
                this.$cookies.remove('token', null);
                this.$data.loginError = false;
                this.$data.isLoggedIn = false;
                this.$data.loginUser = "";
                this.$data.loginPass = "";
            },
            loadWorkRoles: function() {
                this.$data.workItems = [];
                this.$data.visibleWorkItems = [];

                this.$data.vfapi.getAuthenticatedItems("work_role", this.$data.loginToken).then(data => {
                    data.forEach((item) => {
                        item.checked = false;
                        this.$data.workItems.push(item);
                        this.$data.visibleWorkItems.push(item);
                    });
                });
            },
            switchAll: function() {
                if(this.$data.allChecked) {
                    this.$data.allChecked = false;

                    this.$data.visibleWorkItems.forEach((workItem) => {
                        workItem.checked = false;
                    });
                } else {
                    this.$data.allChecked = true;

                    this.$data.visibleWorkItems.forEach((workItem) => {
                        workItem.checked = true;
                    });
                }
            },
            search: function(searchQuery) {
                if(this.$data.searchTimeout) {
                    clearTimeout(this.$data.searchTimeout);
                }

                this.$data.searchTimeout = setTimeout(() => {
                    if(searchQuery === "") {
                        this.$data.visibleWorkItems = this.$data.workItems;
                    } else {
                        this.$data.visibleWorkItems = [];
                        this.$data.workItems.forEach((workItem) => {
                           if(workItem.role_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                               workItem.role_work.toLowerCase().includes(searchQuery.toLowerCase()) ||
                               workItem.role_studio.toLowerCase().includes(searchQuery.toLowerCase()) ||
                               workItem.role_direction.toLowerCase().includes(searchQuery.toLowerCase())) {
                               this.$data.visibleWorkItems.push(workItem);
                           }
                        });
                    }
                }, 500);
            }
        },
        watch: {
            searchQuery: function (val) {
                this.search(val);
            },
        }
    };
</script>

<style scoped lang="less">
@media screen {
    .login {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        & .login-box {
            background: rgba(255,255,255,0.07);
            padding: 25px;
            width: 90%;
            max-width: 400px;
            border-radius: 6px;
            display: grid;
            grid-template-rows: 1fr;
            grid-gap: 10px;

            & .login-error {
                background: rgba(255,0,0,0.15);
                border: 2px solid rgba(255,0,0,0.5);
                padding: 15px;
                text-align: center;
                border-radius: 4px;
            }
            & input, & textarea {
                background: rgba(255,255,255,0.1);
                border: 2px solid transparent;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                padding: 10px;
                border-radius: 6px;
                color: #fff;
                transition: 0.2s ease all;

                &:hover {
                    background: rgba(255,255,255,0.2);
                }
                &:focus {
                    outline: none;
                    border-color: #fff;
                }
            }
            & button {
                justify-self: right;
                border: 0px;
                margin-top: 20px;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: bold;
                background: #fff;
                color: #222;
                padding: 8px 25px;
                border-radius: 100px;
                transition: 0.2s ease-in-out all;
                text-align: center;

                &:not(.button-disabled):hover {
                    opacity: 0.6;
                    cursor: pointer;
                }
                &:not(.button-disabled):active {
                    opacity: 0.8;
                }
                &.button-disabled {
                    opacity: 0.2;
                    cursor: not-allowed;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .vita-dashboard {
        display: grid;
        grid-template-rows: auto 1fr;

        & header {
            background: rgba(255,255,255,0.07);
            padding: 15px 25px;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr auto;

            & span {
                font-weight: 700;
                font-size: 20px;
            }

            & .button-actions {
                & button {
                    border: 0px;
                    margin-left: 10px;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: #fff;
                    color: #222;
                    padding: 8px 25px;
                    border-radius: 100px;
                    transition: 0.2s ease-in-out all;
                    text-align: center;

                    &:not(.button-disabled):hover {
                        opacity: 0.6;
                        cursor: pointer;
                    }

                    &:not(.button-disabled):active {
                        opacity: 0.8;
                    }

                    &.button-disabled {
                        opacity: 0.2;
                        cursor: not-allowed;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        & .search {
            padding: 25px;
            background: rgba(255,255,255,0.14);
            display: flex;
            justify-content: center;

            & input {
                background: rgba(255,255,255,0.1);
                border: 2px solid transparent;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                padding: 10px;
                max-width: 500px;
                width: 100%;
                border-radius: 6px;
                color: #fff;
                transition: 0.2s ease all;

                &:hover {
                    background: rgba(255,255,255,0.2);
                }
                &:focus {
                    outline: none;
                    border-color: #fff;
                }
            }
        }
        & .general-info {
            display: none;
        }
        & .items {
            & .item {
                display: grid;
                grid-template-columns: 30px 60px 1fr 1fr 1fr 1fr;
                grid-gap: 15px;
                padding: 10px;
                border-bottom: 1px solid rgba(255,255,255,0.2);

                &.header {
                    font-weight: bold;
                }

                &.checked {
                    background: rgba(0,255,0,0.15);
                }
            }
        }
    }
}
@media print {
    .login {
        display: none;
    }
    .vita-dashboard {
        & header {
            display: none;
        }
        & .search {
            display: none;
        }
        & .general-info {
            display: grid;
            grid-template-columns: 100px auto auto;
            grid-gap: 40px;
            font-size: 10px;
            color: #000;
            margin-bottom: 20px;

            & img {
                width: 100px;
                height: 100px;
            }
            & div {
                line-height: 1.5em;

                & ul {
                    margin: 5px;
                    padding: 0px 10px;
                }
            }
        }
        & .items {
            font-size: 10px;
            color: #000;

            & .item:not(.checked):not(.header) {
                display: none;
            }
            & .header {
                font-weight: bold;
            }
            & .item {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 10px;
                padding: 5px;
                border-bottom: 1px solid rgba(0,0,0,0.2);

                & div:nth-child(1) { display: none; }
                & div:nth-child(2) { display: none; }
            }
        }
    }
}
</style>
