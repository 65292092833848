<template>
    <div class="page">
        <Header />
        <router-view/>
        <Footer />

        <BackToTopButton />

        <StudioSpecs />
    </div>
</template>

<script>
    import Header from '@/components/Base/Header.vue';
    import BackToTopButton from '@/components/Base/BackToTopButton.vue';
    import Footer from '@/components/Base/Footer.vue';
    import StudioSpecs from '@/components/StudioSpecs/StudioSpecs.vue';

    export default {
        name: 'Base',
        components: {
            Header,
            BackToTopButton,
            Footer,
            StudioSpecs,
        },
        mounted: function() {
        },
        methods: {
        }
    };
</script>

<style lang="less">
    body {
        background: #0C0C0C;
        color: #fff;
        width: 100%;
        height: 100vh;
        user-select: none;
    }
</style>