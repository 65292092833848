const axios = require('axios');

class VFAPI {
    constructor() {
        this.apiBase = "https://vincentfallow.laura.media/public/vincent-fallow/";
    }

    async getItems(collectionName, collectionFields = "*") {
        let apiPath = this.apiBase + "items/" + collectionName + "?fields=" + collectionFields;

        return axios.get(apiPath)
        .then(function(response) {
            return response.data.data;
        }).catch(function(error) {
            throw error;
        });
    }

    async getAuthenticatedItems(collectionName, authToken) {
        let apiPath = this.apiBase + "items/" + collectionName;

        return axios.get(apiPath,{
                headers: {
                    'Authorization': 'bearer ' + authToken
                }
            })
            .then(function(response) {
                return response.data.data;
            }).catch(function(error) {
                throw error;
            });
    }

    async getFileUrl(fileID) {
        let apiPath = this.apiBase + "files/" + fileID;

        return axios.get(apiPath)
        .then(function(response) {
            return response.data.data.data.full_url;
        }).catch(function(error) {
            throw error;
        });
    }

    async postItem(collectionName, data) {
        let apiPath = this.apiBase + "items/" + collectionName;

        return axios.post(apiPath, data)
        .then(function(response) {
            return response.data.data;
        }).catch(function(error) {
            throw error;
        });
    }

    async authenticate(username, password) {
        let apiPath = this.apiBase + "auth/authenticate";

        return axios.post(apiPath, {
            email: username,
            password: password
        }).then(function(response) {
            return response.data.data;
        }).catch(function(error) {
            throw error;
        });
    }
}

module.exports = VFAPI;
